//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ } from '~/utils/butterUtils'
import snarkdown from 'snarkdown'

export default {
  props: [ 'url',
           'dict',
           'selectedState',
           'voterType',
           'voterTypeDescription'
  ],
  data () {
    return {
      jurisdiction: '',
      voterIsRegistered: ''
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    voterTimeCrunch () {
      return this.$store.state.voterTimeCrunch
    },
    selectedLeo () {
      return this.$store.state.leo.leo ? this.$store.state.leo.leo : {}
    },
    butterLeos () {
      return this.$store.state.butterLeos
    }
  },
  methods: {
    anonymousStart () {
      this.$buefy.toast.open({
        message: this.dict.D00,
        type: 'is-success',
        duration: 8000
      })
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.dispatch('userdata/clearVoterData')
      this.$router.push(this.localePath({ name: 'request-stage', params :{ stage: 'start-fpca' } }))
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    md (md) {
      return snarkdown(md)
    },
  },
}
